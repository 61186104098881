<template>
  <v-container id="dashboard" class="pa-4 pa-sm-8">
    <v-row>
      <!-- タイトル -->
      <v-col cols="12" md="12">
        <v-card id="title">
          <div class="titleimg d-flex justify-center flex-column">
            <div class="date mb-5">{{ nowDate | moment("M月D日(ddd) HH:mm") }}</div>
            <template v-if="todaysEvents.length">
            <div>本日の公開予定は<span class="font-weight-bold text-h6 mx-1">{{todaysEvents.length}}件</span>です{{todaysEvents.id}}</div>
            <v-chip-group show-arrows="">
               <v-tooltip top v-for="(item, i) of todaysEvents" :key="i">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" large :class="{primary: item.current_status_id <= 5}" :to="{ name: 'admin:request_show', params: { id: item.id } }">#{{ item.id }}</v-chip>
                </template>
                【{{ item.current_status.status }}】&nbsp;{{ item.subject }}
                </v-tooltip>
            </v-chip-group>
            </template>
            <div v-else>本日の公開予定はありません<v-icon right>mdi-coffee</v-icon></div>
            <v-scroll-x-transition>
              <div v-if="topicsOfficialnews && updateCalendarReady" class="news mx-10 mt-10">
                <span>{{ topicsOfficialnews.pubDate | moment("M月D日") }}</span>
                <v-chip small color="primary" text-color="white"  class="mx-2" style="background: linear-gradient(105deg, #4db3ce 0%, #83c955 100%)">{{ topicsOfficialnews.category }}</v-chip>
                <a :href="`${topicsOfficialnews.link}?utm_source=app&utm_medium=referral`" target="_blank" rel="noopener">{{ topicsOfficialnews.title }}<v-icon class="align-baseline ml-1">mdi-open-in-new</v-icon></a>
              </div>
            </v-scroll-x-transition>
          </div>
          <v-card-title class="">{{ loginUserInfo.name }}さん、こんにちは。</v-card-title>
          <v-divider />
          <v-card-actions>
            <v-row no-gutters>
              <v-col cols="12" sm="4">
                <v-btn text block x-large :to="{ name: 'admin:request_index', params: { fillter: 'all' } }"><v-icon color="primary" size="28" left>mdi-format-list-bulleted-square</v-icon>依頼一覧</v-btn>
              </v-col>
              <v-col cols="12" sm="4">
                <v-btn text block x-large :to="{ name: 'admin:news_new_form' }"><v-icon size="28" color="primary" left>mdi-pencil-plus-outline</v-icon>新規お知らせ</v-btn>
              </v-col>
              <v-col cols="12" sm="4">
                <v-btn text block x-large :to="{ name: 'admin:client_new_form' }"><v-icon size="28" color="primary" left>mdi-domain-plus</v-icon>新規クライアント</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- 最新のコメント -->
      <v-col cols="12" sm="6">
        <v-card class="with-counts" height="100%">
          <v-card-title>最新のコメント</v-card-title>
          <v-card-text class="pb-0">
            <v-alert v-show="!newComments.length" type="info" dense text color="grey">新しいコメントはありません</v-alert>
            <v-list-item v-for="(item, i) in newComments.slice(0, 10)" :key="`first-${i}`" class="px-0" :to="{ name: 'admin:request_show', params: { id: item.request.id } }">
                <v-list-item-avatar width="32" height="32" min-width="32" class="overflow-visible mr-2">
                  <LoginAvatar :user="item.submit_login" :size="32" />
                </v-list-item-avatar>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">
                      {{ item.comment_title }}
                    </v-list-item-title>
                  </template>
                  <span>【{{ item.request.submit_login.end_user.end_company_name }}】【#{{ item.request.id }}】{{ item.request.subject }}</span>
                </v-tooltip>
                <v-list-item-action class="ml-0">
                    <span><v-chip x-small outlined>{{ item.create_at | moment("from") }}</v-chip></span>
                </v-list-item-action>
            </v-list-item>
            <!-- more -->
            <v-list-item v-for="(item, i) in newComments.slice(11)" :key="`more-${i}`" class="px-0" :to="{ name: 'admin:request_show', params: { id: item.request.id } }" v-show="isCommentsMore">
                <v-list-item-avatar width="32" height="32" min-width="32" class="overflow-visible mr-2">
                  <LoginAvatar :user="item.submit_login" :size="32" />
                </v-list-item-avatar>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-bind="attrs" v-on="on">
                      {{ item.comment_title }}
                    </v-list-item-title>
                  </template>
                  <span>【{{ item.request.submit_login.end_user.end_company_name }}】【#{{ item.request.id }}】{{ item.request.subject }}</span>
                </v-tooltip>
                <v-list-item-action>
                    <span><v-chip x-small outlined>{{ item.create_at | moment("from") }}</v-chip></span>
                </v-list-item-action>
            </v-list-item>
          </v-card-text>
          <v-card-actions class="pa-0" v-if="newComments.length > 10">
            <v-spacer />
            <v-btn rounded small color="white" elevation="1" @click="isCommentsMore = !isCommentsMore" class="more">
              <v-icon small v-show="!isCommentsMore">mdi-arrow-collapse-down</v-icon>
              <v-icon small v-show="isCommentsMore">mdi-arrow-collapse-up</v-icon>
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- 自分の確認待ちの依頼 -->
      <v-col cols="12" sm="6">
        <v-card class="with-counts" height="100%">
          <v-card-title>自分の確認待ちの依頼
            <v-btn v-if="reviewRequestsTotal" class="counts" fab elevation="3" color="white" :to="{ name: 'admin:request_index', params: { fillter: 'review' } }">
              <span class="font-weight-bold text-h6 primary--text" >{{ reviewRequestsTotal }}</span>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-alert v-show="!reviewRequests.length" type="info" dense text color="grey">自分の確認待ちの依頼は現在ありません</v-alert>
            <v-tooltip top v-for="(item, i) in reviewRequests" :key="i">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item  class="px-0" v-bind="attrs" v-on="on" :to="{ name: 'admin:request_show', params: { id: item.id } }">
                  <v-list-item-title>
                    <v-chip small class="primary">#{{ item.id }}</v-chip>
                    {{ item.subject }}</v-list-item-title>
                </v-list-item>
              </template>
              <span>公開希望日 {{ item.release_date }}</span>
            </v-tooltip>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- 月別公開カレンダー -->
      <v-col cols="12" md="12">
        <v-card>
          <v-toolbar flat dense>
            <v-spacer />
            <v-toolbar-title v-if="calMonth">{{ calMonth.title }}の公開予定</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items v-if="calMonth">
              <v-btn class="dense" text @click="setToday">今月</v-btn>
              <v-btn fab text small color="" @click="prev"><v-icon small>mdi-chevron-left</v-icon></v-btn>
              <v-btn fab text small color="" @click="next"><v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-sheet>
            <v-calendar class="fullCal" ref="calMonth" v-model="focus" @change="updateCalendar" color="primary" :weekdays="weekdays" @click:date="dateClick" :events="events" @click:event="eventClick" :event-more="false">
              <template v-slot:event="{ event }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on" :class="['event', {active: event.current_status_id <= 5}, {cancel: event.current_status.is_cancel}, {complete: event.current_status.is_complete}]"></span>
                  </template>
                【#{{ event.id }}】{{ event.subject }} {{ event.release_time }}
                </v-tooltip>
              </template>
            </v-calendar>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import moment from 'moment'
import LoginAvatar from '@/components/LoginAvatar.vue'

export default {
  name: 'Dashboard',
  components: { LoginAvatar },
  data: () => ({
    ready: false,
    updateCalendarReady: false,
    value: '',
    type: '',
    focus: '',
    nowDate: '',
    currentMonth: '',
    reviewRequests: [],
    reviewRequestsTotal: 0,
    newComments: [],
    events: [],
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    isCommentsMore: false,
    officialnews: []
  }),
  methods: {
    tick () {
      this.nowDate = new Date()
      setTimeout(() => {
        this.tick()
      }, 1000)
    },
    readDataFromAPI () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request_comment/recent`)
        .then(res => {
          // console.log(res.data)
          this.newComments = res.data
        })
        .catch(err => {
          console.log(err)
        })
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request`, {
        params: {
          schedule: 'review',
          per_page: 12
        }
      })
        .then(res => {
          this.reviewRequests = res.data.data
          this.reviewRequestsTotal = res.data.total
        })
        .catch(err => {
          console.log(err)
        })
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/biz/news`)
        .then(res => {
          this.officialnews = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCurrentTime () {
      return this.calDay ? this.calDay.times.now.hour * 60 + this.calDay.times.now.minute : 0
    },
    next () {
      this.calMonth.next()
    },
    prev () {
      this.calMonth.prev()
    },
    setToday () {
      this.focus = ''
    },
    eventClick (e) {
      const eventId = e.event.id
      if (eventId) this.$router.push(`/web/admin/request/${eventId}`)
    },
    dateClick (e) {
      const date = e.date
      if (date) this.$router.push(`/web/admin/request/all?release_date_from=${date}&release_date_to=${date}`)
    },
    updateCalendar (data) {
      if (data) {
        axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/calendar/${data.start.year}/${data.start.month}`)
          .then(res => {
            var arr = []
            res.data.forEach((item) => {
              item.start = item.release_time ? item.release_date + ' ' + item.release_time : item.release_date
              if (item.release_time != null) {
                item.timed = true
              }
              arr.push(item)
            })
            this.events = arr
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.$nextTick(() => {
              this.updateCalendarReady = true
            })
          })
      }
    },
    getStepPercentage: function (step) {
      if (step === 8) return 0
      var allStatus = this.$store.getters.getAllStatus
      return Math.ceil(step / (allStatus.length - 1) * 100)
    }
  },
  computed: {
    loginUserInfo: function () {
      return this.$store.getters.loginUserInfo
    },
    calMonth () {
      return this.ready ? this.$refs.calMonth : null
    },
    todaysEvents () {
      return this.events?.filter(el => el.release_date === moment().format('YYYY-MM-DD'))
    },
    topicsOfficialnews () {
      const filtered = this.officialnews.filter((item) => {
        return moment(item.pubDate).isAfter(moment().subtract(10, 'days')) // 10日以内のお知らせを取得
      })
      if (filtered.length === 0) {
        return null
      }
      return filtered[0]
    }
  },
  created () {
    this.tick()
    this.readDataFromAPI()
    this.updateCalendar()
  },
  mounted () {
    this.ready = true
  }
}
</script>

<style scoped lang="scss">

.v-chip.v-size--large{
  height: 30px;
}

.titleimg{
  .date{
    font-size: min(8vw, 38px);
    font-family: sans-serif;
    color: var(--v-primary-darken1);
    background: -webkit-linear-gradient(0deg, var(--v-primary-base), var(--v-secondary-base));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .news {
    font-size: 1.5rem;
    .v-chip {
      background: linear-gradient(105deg, #4db3ce 0%, #83c955 100%);
      vertical-align: text-bottom;
    }
    a {
      color: rgba(0, 0, 0, 0.87);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// .v-current-time {
//   height: 2px;
//   background-color: #ea4335;
//   position: absolute;
//   left: -1px;
//   right: 0;
//   pointer-events: none;

//   &.first::before {
//     content: '';
//     position: absolute;
//     background-color: #ea4335;
//     width: 12px;
//     height: 12px;
//     border-radius: 50%;
//     margin-top: -5px;
//     margin-left: -6.5px;
//   }
// }

.v-list-item__action{
  flex: none;
}

//本日の公開予定
::v-deep .v-calendar .v-event{
  display: inline-block;
  // width: auto!important;
  border-radius: 15px;
  vertical-align: middle;
  margin: 3px;
  // padding: 0 .2em;
  .event{
    width: 100%;
    height: 100%;
    display: block;
  }
}

//〇〇の公開予定
::v-deep .v-calendar.fullCal{

  .v-event {
    width: 10px!important;
    height: 10px!important;
    background: transparent!important;
    span{
      background-color: #aaa;
      &.active{
        background-color: var(--v-primary-base);
      }
      &.complete{
        background-color: #eee;
      }
      &.cancel{
        background-color: #eee;
        // background-color: var(--v-warning-lighten3);
      }
    }
  }
  .v-calendar-weekly__day{
    text-align: center!important;
    min-height: 90px;
  }
}

</style>
